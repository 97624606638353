.cc-revoke, .cc-window {
    font-size: u(1.4rem);
}

.cc-window {
    background: $primary-light;
    color: $dark;
    bottom: u(7.5rem);

    a {
        color: $primary-dark;
    }
}
