//generate no-alpha background
@mixin alpha($property, $color, $alpha) {
    #{$property}: $color;
    #{$property}: rgba($color, $alpha);
}

// genereate fallbacks for alpha propriety
@mixin opacity($opacity) {
    opacity: $opacity;
    $opacity-ie: $opacity * 100;
    filter: alpha(opacity=$opacity-ie); //IE8
}
