#page-homepage {
    .welcome {
        position: relative;
        height: u(85rem);
        color: #FFF;

        @media (max-width: $screen-xs-max) {
            height: auto;
        }

        &:before {
            position: absolute;
            display: block;
            width: 100%;
            height: 100%;
            background-image: url('../images/pattern.png');
            background-blend-mode: multiply;
            @include opacity(.05);
            z-index: 0;
            content: '';

            @media #{$retina} {
                background-image: url('../images/@2x/pattern.png');
                background-size: u(70rem);
            }
        }

        .container {
            position: relative;
        }
    }

    .welcome-bottom {
        position: relative;
        width: 100%;

        @media (max-width: $screen-xs-max) {
            margin-bottom: u(4.5rem);
        }
    }

    .hero {
        padding-top: u(20rem);

        @media (max-width: $screen-xs-max) {
            padding-top: u(20rem);
        }

        .btn {
            @media (max-width: $screen-xs-max) {
                position: relative;
                z-index: 1;
            }
        }
    }

    .title {
        @media (max-width: $screen-xs-max) {
            padding: u(0 1.5rem);
        }
    }

    .section {
        @media (max-width: $screen-xs-max) {
            position: relative;
            height: auto;

            &:before {
                top: 0;
            }
        }

        .container, .row, .col {
            position: relative;
            height: 100%;

            @media (max-width: $screen-xs-max) {
                height: auto;
            }
        }

        .subtitle {
            @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
                font-size: u(1.4rem);
            }
        }
    }
}

.step-description {
    position: relative;
    height: 100%;
    z-index: 9;

    .title, .subtitle, .btn {
        opacity: 0;
        transform: translateY(15%);
        transition: opacity .5s ease, transform .6s ease;
    }

    .title {
        font-size: u(3.4rem);

        @media (max-width: $screen-sm-max) {
            font-size: u(2.4rem);
        }

        @media (max-width: $screen-xs-max) {
            font-size: u(3rem);
        }
    }

    .step-description-container {
        padding-left: u(4.5rem);

        @media (max-width: $screen-sm-max) {
            padding: 0;
        }

        @media (max-width: $screen-xs-max) {
            padding: 0 u(3rem);
            text-align: center;
        }
    }

    @media (max-width: $screen-xs-max) {
        padding-bottom: u(2rem);
    }
}

#play-store-rating {
    margin-top: u(3rem);
    margin-bottom: u(6rem);

    .icon-star {
        position: relative;
        top: u(.6rem);
        margin-bottom: u(1.5rem);
        font-size: u(3rem);
        color: $star-color;
        letter-spacing: -7px;
    }

    a {
        font-size: u(2.6rem);
        color: $dark;
        font-weight: 700;

        @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
            font-size: u(2.4rem);
        }

        @media (max-width: $screen-xs-max) {
            display: block;
        }
    }
}

.reveal-chat-el {
    opacity: 0;
    transform: scale(.5);
    transform-origin: center center;
    transition: transform 1s cubic-bezier(.17,.67,.16,1.09), opacity .4s ease;
}

#pic-dog {
    .reveal-chat-el {
        transition-delay: .1s;
    }
}

#emoji-fish {
    .reveal-chat-el {
        transition-delay: .3s;
    }
}

#emoji-heart {
    .reveal-chat-el {
        transition-delay: .45s;
    }
}

#emoji-cat {
    .reveal-chat-el {
        transition-delay: .5s;
    }
}


.page-loaded {
    .reveal-chat-el {
        opacity: 1;
        transform: scale(1);
    }
}

.home-welcome-chat-el {
    position: absolute;
    display: block;
    transition: transform 1s ease;

    @media (max-width: $screen-md-max) {
        display: none;
    }

    &#pic-kid-at-sea {
        width: u(20rem);
        top: u(44rem);
        left: 0;
        transform: rotate(-9deg);
    }

    &#pic-dog {
        width: u(20rem);
        top: u(22rem);
        right: 0;
        transform: rotate(-8deg);
    }
}

.emoji {
    z-index: 2;

    img {
        width: u(4rem);
    }

    .reveal-chat-el {
        padding: u(1rem);
        border-radius: 7px;
        box-shadow: 0 3px 20px rgba(0,0,0,.15) ,0 0 20px rgba(0,0,0,.1);
        background-color: #fff;
    }

    &#emoji-fish {
        top: u(40rem);
        left: u(15rem);
        transform: rotate(-7deg);
    }

    &#emoji-cat {
        bottom: u(13rem);
        left: u(15rem);
        transform: rotate(-7deg);
    }

    &#emoji-heart {
        top: u(19rem);
        right: 0;
        transform: rotate(-7deg);
    }
}

.step-counter {
    position: relative;
    display: block;
    width: u(7rem);
    height: u(7rem);
    margin: auto;
    background-color: $primary;
    color: #FFF;
    font-size: u(3rem);
    font-weight: 600;
    text-align: center;
    line-height: u(7rem);
    border-radius: 100%;
    transform: scale(0);
    transition: transform 1s cubic-bezier(.24,.78,0,1.22);

    @media (max-width: $screen-xs-max) {
        margin: u(2rem) auto 0 auto;
        width: u(4.5rem);
        height: u(4.5rem);
        font-size: u(2.4rem);
        line-height: u(4.5rem);
    }
}

.phone-screen {
    position: absolute;
    width: 251px;
    height: 519px;
    top: 22px;
    left: 50%;
    margin-left: -126px;
    z-index: 3;
    border-radius: u(2rem);
    overflow: hidden;

    @media (max-width: $screen-sm-max) {
        width: 205px;
        height: 424px;
        margin-left: -102px
    }

    @media (max-width: $screen-xs-max) {
        width: 205px;
        height: 424px;
        margin-left: -102px
    }

    &:before, &:after {
        position: absolute;
        display: block;
        width: u(1.6rem);
        height: 100%;
        top: 0;
        opacity: .14;
        z-index: 4;
        transform: translate3d(0,0,0);
        content: '';
    }

    &:before {
        left: 0;
        background-image: linear-gradient(-90deg, #FFFFFF 0%, #000000 100%);
    }

    &:after {
        right: 0;
        background-image: linear-gradient(90deg, #FFFFFF 0%, #000000 100%);
    }
}

.screen-header {
    position: relative;
    height: u(5rem);
    background: #FFF;
    box-shadow: 0 u(.1rem) u(.6rem) 0 rgba(0,0,0,0.15);
    z-index: 1;
    transform: translate3d(0,0,0);

    .icon {
        position: relative;
        top: u(1.4rem);
        right: u(2rem);
    }
}

.screen-chat-name {
    font-weight: 600;
    line-height: u(5rem);
}

.screen-chat-avatar {
    display: block;
    margin: u(1rem -4.5rem 1rem 3rem);
    float: left;
    width: u(3rem);
    height: u(3rem);
    background-color: $primary-dark;
    color: #FFF;
    line-height: u(3rem);
    text-align: center;
    border-radius: 100%;
}

.screen-text-input {
    position: absolute;
    width: 95%;
    height: u(5rem);
    bottom: u(6rem);
    left: 2.5%;
    background: #FFF;
    box-shadow: u(0 .1rem .2rem 0) rgba(0,0,0,0.17);
    border-radius: u(.4rem);
    z-index: 1;
    transform: translate3d(0,0,0);

    .icon {
        margin: u(1.5rem);
        float: right;
        color: $primary;
        opacity: .3;
    }
}

.screen-chat-container {
    position: relative;
    width: 100%;
    height: auto;
    top: 0;
    left: 0;
    padding: u(1.5rem);
    overflow: hidden;
    z-index: 0;
}

.screen-message {
    position: relative;
    float: left;
    width: 70%;
    margin: u(.7rem .5rem);
    padding: u(.5rem);
    background-color: #FFF;
    box-shadow: u(0 .1rem .2rem 0) rgba(0,0,0,0.17);
    border-radius: u(.4rem);

    &.screen-message-vert {
        float: right;
        background-color: $primary-light;
    }

    &:before, &:after {
        position: relative;
        display: block;
        width: 90%;
        height: u(.8rem);
        margin: 5%;
        background-color: $dark;
        @include opacity(.2);
        content: '';
    }
}

.screen-navbar {
    position: absolute;
    width: 100%;
    height: u(4.5rem);
    bottom: 0;
    left: 0;
    background-color: rgba(#000, .3);
    transform: translate3d(0,0,0);

    img {
        position: relative;
        top: 50%;
        margin-top: (-2rem);
    }
}

#background-shape {
    position: absolute;
    display: block;
    width: 100%;
    height: u(20rem);
    right: 0;
    bottom: 0;

    @media (max-width: $screen-xs-max) {
        position: relative;
    }
}

#phone-container {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    transform: translate3d(0, 60rem, 0);
    top: 0;
    z-index: 9;
    pointer-events: none;
    transition: transform .7s cubic-bezier(.61,.6,.51,1.03);
    // transition: transform .5s cubic-bezier(.61,.6,.51,1.03);

    @media (max-width: $screen-xs-max) {
        position: relative;
        margin: -160px 0 u(1.5rem) 0;
        transform: none;
    }
}

.phone {
    position: relative;
    width: 100%;
    text-align: center;
    z-index: 9;
    transform: translate3d(0,0,0);
}

.phone-device {
    position: relative;
    width: 260px;
    margin: auto;

    @media (max-width: $screen-sm-max) {
        width: 211px;
        height: 469px;
    }
}

#download-badge {
    position: absolute;
    top: u(70rem);
    right: 0;
    z-index: 1;

    @media (max-width: $screen-xs-max) {
        position: relative;
        display: block;
        width: u(19rem);
        max-width: 100%;
        margin: u(1.5rem) auto;
        top: auto;
    }
}

#step-introduction {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: u(-9rem);
    font-size: u(2.4rem);
    color: $dark;
    font-weight: 600;
    pointer-events: all;
    opacity: 0;
    transform: translateY(50%);
    transition: opacity .4s ease, transform .4s ease;
}

// Fullpage
.fp-enabled {
    .fade {
        opacity: 0;
        transition-delay: .4s;
    }

    .translate-left {
        transform: translateX(-20%);
        transition: transform .4s ease;
    }

    .translate-right {
        transform: translateX(20%);
        transition: transform .8s ease;
    }

    .translate-bottom {
        transform: translateY(50%);
        transition: transform .8s ease;
    }

    .translate-top {
        transform: translateY(-50%);
        transition: transform .8s ease;
    }

    .baloons-container li {
        opacity: 0;
        transform: translateX(-15%) scale(.8);
        transition: opacity .8s ease-out, transform 1.2s cubic-bezier(.31,.6,.12,1.18);
        transform-origin: left center;

        &:nth-child(1) {
            transition-delay: .15s;
        }

        &:nth-child(2) {
            transition-delay: .4s;
        }

        &:nth-child(3) {
            transition-delay: .3s;
        }

        &:nth-child(4) {
            transition-delay: .5s;
        }
    }

    .screen-message, .screen-photo {
        opacity: 0;
        transform: translateY(100%);
        transition: all .7s ease;
        transition-delay: 1s;
    }

    .screen-photo {
        transform: translateY(100%) scale(.5);
        transform-origin: center;
    }

    .screen-chat-container {
        transform: translateY(0);
        transition: transform 1s ease;
        transition-delay: 1s;
    }
}

.currently-viewing-0 {

}

.currently-viewing-1, .currently-viewing-2 {
    #phone-container {
        transform: translate3d(0, 120%, 0);

        @media (min-width: $screen-md-min) and (max-height: 768px) {
            transform: translate3d(0, 108%, 0);
        }

        @media (max-width: $screen-xs-max) {
            transform: none;
        }
    }
}

.currently-viewing-1 {
    .screen-message, .screen-photo {
        opacity: 1;
        transform: translateY(0);
    }

    .screen-photo {
        transform: translateY(0) scale(1);
    }

    .screen-chat-container {
        transform: translateY(-45%);
    }

    #step-introduction {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
        transition-delay: .6s;
    }

    .bouncing-arrow {
        animation-name: bounce;
        animation-duration: 1.2s;
        animation-iteration-count: infinite;
        animation-timing-function: ease;
        animation-delay: 5s;
    }
}

.section.active {
    .fade {
        opacity: 1;
    }

    .translate-top, .translate-right, .translate-bottom, .translate-left {
        transform: translate(0);
    }

    .baloons-container li {
        opacity: 1;
        transform: translateX(0) scale(1);
    }

    .step-counter {
        transform: scale(1);
        transition-delay: .5s;

        @media (max-width: $screen-xs-max) {
            transition-delay: 0;
        }
    }

    .step-description {
        .title, .subtitle, .btn {
            opacity: 1;
            transform: translateY(0);
        }

        .title {
            transition-delay: .4s;
        }

        .subtitle {
            transition-delay: .5s;
        }

        .btn {
            transition-delay: .6s;
        }
    }

    .bouncing-arrow {
        opacity: 1;
        transform: translateY(0);
        cursor: pointer;
        transition-delay: .8s;

        animation-name: bounce;
        animation-duration: 1.2s;
        animation-iteration-count: infinite;
        animation-timing-function: ease;
    }

    .scroll-down {
        .bouncing-arrow {
            animation-delay: 2s;
        }

        @media (max-width: $screen-xs-max) {
            display: none;
        }
    }

    .item {
        li {
            position: relative;
            display: block;
            opacity: 0;
            transform: translateY(250px) scale(.5);
            transition: opacity .4s ease, transform .8s cubic-bezier(.13,.85,.21,1.06);
        }

        li:nth-child(2) {
            transition-delay: .15s;
        }

        li:nth-child(3) {
            transition-delay: .3s;
        }

        &.active {
            li {
                opacity: 1;
                transform: translateY(0) scale(1);
            }
        }
    }
}

.scroll-down {
    position: absolute;
    bottom: 50px;
    width: 100%;

    .bouncing-arrow {
        position: relative;
        display: inline-block;
        left: 0;
        background-color: transparent;
        color: $primary;
        opacity: 0;
        transform: translateY(50%);
        transition: opacity .4s ease, transform .4s ease;
    }
}

.bouncing-arrow {
    position: relative;
    display: inline-block;
    width: u(5rem);
    height: u(5rem);
    top: u(1rem);
    left: u(-2.5rem);
    background-color: $primary;
    font-size: u(3.6rem);
    color: #FFF;
    text-align: center;
    line-height: u(5rem);
    border-radius: 100%;
    content: '';
}

#scroll-bottom {
    position: absolute;
    display: block;
    width: u(6rem);
    height: u(6rem);
    left: 50%;
    bottom: u(3rem);
    margin-left: (-3rem);
    background-color: #fff;
    text-align: center;
    border-radius: 100%;
    z-index: 10;
    opacity: 1;
    box-shadow: 0 10px 60px rgba(0,0,0,.3);
    cursor: pointer;
    transform: scale(1);
    transition: opacity .5s ease;

    animation-name: bounce;
    animation-duration: 1.2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease;

    &.hided {
        cursor: none;
        pointer-events: none;
        opacity: 0;
        transform: scale(0);
        animation-play-state: paused;
    }

    .icon {
        display: block;
        font-size: u(3rem);
        color: $primary;
        line-height: u(6rem);
    }

    @media (max-width: $screen-xs-max) {
        display: none;
    }
}

#slide-1 {
    position: absolute;
    display:block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding-top: u(.5rem);
    background-color: $screen-bg;
    text-align: center;
    overflow: hidden;
    transform: translate3d(0,0,0);
}

#slide-2 {
    position: relative;
    width: 90%;
    top: u(3rem);
    margin: auto;

    @media (max-width: $screen-xs-max) {
        position: absolute;
        width: 70%;
        top: 0;
        left: 15%;
    }
}

#section-backup-extraction {
    @media (max-width: $screen-xs-max) {
        z-index: 1;
        .col-animation-container {
            .align-middle {
                position: relative;
                height: 0;
                padding-bottom: 85%;
            }
        }
    }
}

#slide-3 {
    position: relative;
    top: u(-18rem);

    @media (max-width: $screen-xs-max) {
        position: absolute;
        width: 70%;
        top: auto;
        bottom: 0;
        left: 15%;
    }
}

#section-transfer-backup {
    @media (max-width: $screen-xs-max) {
        z-index: 0;

        .col-animation-container {
            .align-middle {
                position: relative;
                height: 0;
                padding-bottom: 60%;
            }
        }
    }
}

#slide-4 {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    transform: translate3d(0,0,0);
}

#section-import-messages {
    @media (max-width: $screen-xs-max) {
        margin-bottom: u(6rem);
    }

    .screen-header {
        overflow: hidden;

        &:before {
            position: absolute;
            display: block;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: #fff;
            text-align: center;
            font-weight: 700;
            line-height: u(5rem);
            content: 'Wazzap Migrator';
            transition: transform .4s ease;
        }

        .screen-chat-name, .icon {
            transform: translateY(50px);
            position: relative;
            display: block;
            transition: transform .4s ease;
        }
    }

    .screen-text-input {
        opacity: 0;
        transform: translateY(100%);
        transition: opacity .4s ease, transform .4s ease;
    }

    .change-app-ui {
        .screen-header:before {
            transform: translateY(-100%);
        }

        .screen-chat-name, .screen-header .icon  {
            transform: translateY(0);
        }

        .screen-text-input {
            opacity: 1;
            transform: translateY(0);
        }
    }
}

#section-screenshot {
    background-color: #fff;
    overflow: hidden;

    .card.card-customer {
        box-shadow: 0 5px 8px 0 rgba($dark, .1), 0 2px 30px 0 rgba($dark, .1);
    }

    .title-container {
        position: relative;
        height: 100%;
        top: 0;
        left: 0;
        white-space: nowrap;
        z-index: 9;

        @media (max-width: $screen-sm-max) {
            height: auto;
        }

        &:before {
            content: '';
            display: inline-block;
            height: 100%;
            vertical-align: middle;
            margin-right: -0.25em;
        }

        img {
            margin-top: u(1.5rem);

            @media (max-width: $screen-sm-max) {
                margin-bottom: u(6rem);
            }
        }
    }

    .screenshot-container {
        .vertical-align {
            @media (max-width: $screen-sm-max) {
                position: relative;
                height: auto;
            }
        }
    }

    .centered {
        display: inline-block;
        vertical-align: middle;
        text-align: left;

        @media (max-width: $screen-sm-max) {
            text-align: center;
        }
    }

    @media (max-width: $screen-xs-max) {
        padding: u(3rem 0);
    }

    &.section {
        .container, .row {
            height: auto;

            @media (max-width: $screen-xs-max) {
                white-space: nowrap;
                overflow-x: scroll;
                overflow-y: auto;
                -webkit-overflow-scrolling: touch;
            }
        }
    }

    .title {
        font-size: u(3.8rem);
        font-weight: 700;

        @media (max-width: $screen-xs-max) {
            font-size: u(3rem);
        }
    }

    .subtitle {
        font-size: u(2.2rem);

        @media (max-width: $screen-sm-max) {
            font-size: u(1.8rem);
        }
    }

    .card-container {
        margin-top: u(4.5rem);
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        @media (max-width: $screen-sm-max) {
            margin-top: u(1.5rem);
        }

        @media (max-width: $screen-xs-max) {
            display: block;
        }

        >div {
            display: inline-flex;
            flex: 1 0 33%;

            @media (max-width: $screen-xs-max) {
                display: inline-block;
                width: 70%;
                float: none;
                flex: 1 1 auto;
                margin-right: 0
            }
        }

        .card {
            &.card-customer {
                padding-top: 0;
            }

            .card-content {
                padding: 0;

                img {
                    width: 100%;
                }
            }
        }
    }
}

#section-customers {
    background-color: $primary;

    @media (max-width: $screen-xs-max) {
        padding: u(3rem 0);
    }

    &:before {
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        background-image: url('../images/pattern.png');
        background-blend-mode: multiply;
        @include opacity(.03);
        z-index: 0;
        content: '';

        @media #{$retina} {
            background-image: url('../images/@2x/pattern.png');
            background-size: u(70rem);
        }
    }

    &.section {
        .container, .row {
            height: auto;
        }
    }

    .title {
        font-size: u(3.8rem);
        font-weight: 700;
        color: #fff;

        @media (max-width: $screen-xs-max) {
            font-size: u(3rem);
        }
    }

    .subtitle {
        font-size: u(2.2rem);
        color: #fff;

        @media (max-width: $screen-sm-max) {
            font-size: u(1.8rem);
        }
    }

    .card-container {
        margin-top: u(4.5rem);
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        @media (max-width: $screen-sm-max) {
            margin-top: u(1.5rem);
        }

        >div {
            display: inline-flex;
            flex: 1 0 33%;

            @media (max-width: $screen-sm-max) {
                flex: 1 0 100%;
            }
        }
    }
}

#section-screenshot {
    .title-container .centered {
        .container, .row, .row > div {
            overflow: hidden;
        }
    }
}

#section-disqus {
    padding: u(6rem) 0 u(9rem) 0;

    .title {
        font-size: u(3.8rem);
        font-weight: 700;

        @media (max-width: $screen-xs-max) {
            font-size: u(3rem);
        }
    }

    .subtitle {
        margin-bottom: u(4.5rem);
    }
}

.step-section {
    .step-description-container {
        @media (max-width: $screen-xs-max) {
            margin-bottom: u(6rem);
        }
    }
}
