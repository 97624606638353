// typograghy
$sans-serif: 'Roboto', sans-serif;

// theme colors
$primary: #00BFA5 !default;
$primary-dark: darken($primary, 10%) !default;
$primary-light: #D0E9EA !default;

$features-1: #4F49E2 !default;
$features-2: #C88AFE !default;
$features-3: #9E9BEB !default;

$dark: #3E3E3E !default;
$page-bg: #FAFAFA !default;

$screen-bg: #EFE7DE !default;
$star-color: #F6A623 !default;
$border: lighten($dark, 50%);

// social colors
$facebook: #305991 !default;
$twitter: #25A8D2 !default;
$pinterest: #F40024 !default;
$instagram: #0174A2 !default;

// media
$retina: "(-webkit-min-device-pixel-ratio: 2),(min-resolution: 192dpi)" !default;
$landscape: "(orientation: landscape)" !default;

// config
$px-only: false !default;
