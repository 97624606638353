#messages-wrapper {
    display: none;
}

.pgn-wrapper {
    position: fixed;
    z-index: 1000;

    &[data-position$='-left'] {
        left: 30px;
    }

    &[data-position$='-right'] {
        right: 20px;
    }

    &[data-position^='top-'] {
        top: 20px;

        .pgn-flip {
            top: -30px;
        }
    }

    &[data-position^='bottom-'] {
        bottom: 20px;

        .pgn-flip {
            bottom: -30px;

            .alert {
                -webkit-transform-origin: 50% 100%;
                transform-origin: 50% 100%;
            }
        }
    }

    &[data-position='top'] {
        top: 0;
        left: 0;
        right: 0;
    }

    &[data-position='bottom'] {
        bottom: 0;
        left: 0;
        right: 0;
    }
}

.pgn {
    position: relative;
    margin: 10px;

    .alert {
        margin: 0;
        position: relative;

        button {
            position: absolute;
            top: 0;
            right: 0;
            padding: 10px 15px;
        }
    }
}

.pgn-flip .alert {
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    box-shadow: 0 6px 4px -3px rgba(0, 0, 0, 0.2);
    -webkit-animation-name: flipInX;
    animation-name: flipInX;
    -webkit-animation-duration: 0.8s;
    animation-duration: 0.8s;
    border-radius: 0;
    padding: 25px 35px;
    max-width: 500px;
    max-height: 250px;
    overflow: hidden;
}

@-webkit-keyframes flipInX {
    0% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -90deg);
        -webkit-transition-timing-function: ease-in;
    }
    40% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 20deg);
        -webkit-transition-timing-function: ease-out;
    }
    60% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -10deg);
        -webkit-transition-timing-function: ease-in;
        opacity: 1;
    }
    80% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 5deg);
        -webkit-transition-timing-function: ease-out;
    }
    100% {
        -webkit-transform: perspective(400px);
    }
}

@keyframes flipInX {
    0% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -90deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -90deg);
        -webkit-transition-timing-function: ease-in;
        transition-timing-function: ease-in;
    }
    40% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 20deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 20deg);
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
    }
    60% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -10deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -10deg);
        -webkit-transition-timing-function: ease-in;
        transition-timing-function: ease-in;
        opacity: 1;
    }
    80% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 5deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 5deg);
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
    }
    100% {
        -webkit-transform: perspective(400px);
        transform: perspective(400px);
    }
}
