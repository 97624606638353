footer {
    background-color: $dark;

    span.locale-untranslated {
        display: none;
    }

    .language-switcher-locale-url {
        display: inline-block;
        margin: u(0rem 0 1.5rem 0);

        @media (max-width: $screen-xs-max) {
            display: block;
            max-width: 300px;
            margin: u(3rem ) auto u(1.5rem) auto;
        }

        li {
            float: left;
            list-style: none;
            margin-left: u(1rem);
            transition: opacity .3s ease;

            &:first-child {
                margin-left: 0;
            }

            @media (max-width: $screen-xs-max) {
                float: none;
                display: inline-block;
                margin-bottom: u(1.5rem);
            }
        }

        a {
            width: u(3rem);
            height: u(3rem);
            position: relative;
            border-radius: 100%;
            overflow: hidden;
        }

        img {
            width: 100%;
        }

        &:hover {
            li {
                @include opacity(.7);

                &:hover {
                    @include opacity(1);
                }
            }
        }
    }
}

#section-footer {
    padding: u(6rem 0 3rem 0);
    background-color: $dark;

    a {
        display: inline-block;
        color: #fff;;
    }
}

#footer-logo {
	margin-bottom: u(3rem);
	
    .title {
        font-size: u(2.3rem);
        font-weight: 700;
        text-decoration: none;
        transition: opacity .3s ease;

        &:hover {
            @include opacity(.7);
        }
    }
}

#legal-stuff {
    p {
        font-size: u(1.1rem);
        color: #fff;
        line-height: 1.4;
        @include opacity(.7);
    }
}

#social {
    margin-top: u(3rem);

    ul {
        display: inline-block;
    }

    li {
        float: left;
        margin-left: u(2rem);
        transition: .3s ease;

        @media (max-width: $screen-xs-max) {
            display: block;
            float: none;
            width: 100%;
            margin-top: u(1rem);
            margin-left: 0;
        }

        &:hover {
            @include opacity(.7);
        }

        &:first-child {
            margin-left: 0;
        }
    }

    img {
        height: u(2rem);
    }
}

#footer-bottom-section {
    margin-top: u(3rem);
    color: #fff;
}