.navbar-brand {
    color: $dark;

    #brand-name {
        position: relative;
        top: u(-.5rem);
        color: $dark;
        transition: opacity .3s ease;
    }

    &:hover {
        #brand-name {
            @include opacity(.7);
        }
    }
}

.navbar-brand {
    height: auto;
    font-weight: 700;

    .icon {
        position: relative;
        display: inline-block;
        margin-right: u(1rem);
        height: u(4.5rem);
        font-size: u(3.2rem);
        color: $dark;
        line-height: u(4.5rem);
        border-radius: 100%;
    }
}

#site-header {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    text-align: center;
    border: none;
    border-radius: u(.4rem);
    z-index: 9;

    @media (min-width: $screen-lg-min) {
        padding-bottom: u(.6rem);
    }

    @media(max-width: $screen-md-max) {
        border-radius: 0;
    }

    .navbar-default {
        display: inline-block;
        margin-top: u(3rem);
        padding: u(1.7rem 2rem);
        background: #FFF;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.22), 0 1px 12px 0 rgba(0,0,0,0.15);
        border: none;
        border-color: transparent;

        @media(max-width: $screen-md-max) {
            display: block;
            width: auto;
            margin-top: 0;
            margin-right: auto;
            margin-left: auto;
            padding: u(2rem 0 0 0);
            text-align: center;
        }

        @media(max-width: $screen-xs-max) {
            width: 100%;
            margin: 0;
        }
    }

    .navbar-collapse {
        padding: 0;
        float: left;
        text-align: center;

        @media (max-width: $screen-md-max) {
            display: block;
            width: 100%;
            text-align: center;
            margin-top: u(.5rem);
            padding: u(1.5rem 0 .7rem);
        }

        @media (max-width: $screen-xs-max) {
            white-space: nowrap;
            overflow-x: scroll;
            overflow-y: visible;
            -webkit-overflow-scrolling: touch;
        }

        li {
            a {
                color: $dark;
                font-weight: 700;
                text-transform: uppercase;

                @media (min-width: $screen-sm-min) {
                    transition: color .3s ease;

                    &:hover {
                        color: $primary;
                    }
                }
            }

            @media (max-width: $screen-xs-max) {
                display: inline-block;
            }
        }
    }

    .navbar {
        min-height: 0;
        margin-bottom: 0;

        @media(max-width: $screen-md-max) {
            border-radius: 0;
        }
    }

    .navbar-toggle {
        position: relative;
        margin-right: u(1.5rem);
        background-color: transparent;
        border: none;

        &:hover, &:focus {
            &:before {
                animation-name: navbarToggleFeedback;
                animation-duration: .4s;
            }
        }

        &:before {
            position: absolute;
            display: block;
            width: u(5rem);
            height: u(5rem);
            top: u(-.8rem);
            left: -50%;
            margin-left: u(2.2rem);
            background-color: $primary-light;
            border-radius: 100%;
            content: '';
            opacity: 0;
            z-index: 0;
        }

        .icon-bar {
            position: relative;
            background-color: $primary;
            z-index: 1;
        }
    }

    .navbar-header {
        float: left;

        @media(max-width: $screen-md-max) {
            width: 100%;
        }

        @media(max-width: $screen-xs-max) {
            margin-bottom: u(1.5rem);
        }
    }

    .navbar-brand {
        margin-right: u(3rem);
        padding: u(0 3rem 0 0);
        border-right: u(.1rem) solid $border;

        @media (max-width: $screen-md-max) {
            float: none;
            margin-right: 0;
            padding-right: 0;
            border: none;
        }
    }

    .navbar-nav {
        @media (max-width: $screen-xs-max) {
            display: inline-block;
            margin: 4px 0;
        }

        >li {
            margin-left: u(2.5rem);

            @media (max-width: $screen-xs-max) {
                margin-left: 0;
            }

            > a {
                padding: 0;

                @media (max-width: $screen-xs-max) {
                    padding: u(0 1.3rem);
                }
            }

            &:first-child {
                margin-left: 0;
            }
        }

        .active>a {
            position: relative;
            background-color: transparent;

            &:after {
                position: absolute;
                display: block;
                width: 100%;
                height: u(.5rem);
                left: 0;
                bottom: u(-3.2rem);
                background-color: $primary;
                @include opacity(.6);
                content: '';

                @media (max-width: $screen-md-max) {
                    bottom: u(-2.2rem);
                }
            }
        }
    }

    .navbar-right {
        margin-left: u(3rem);
        padding: u(1.5rem 1.5rem 1rem 3rem);
        border-left: u(.1rem) solid $border;

        >li>a {
            color: $primary;
            @include opacity(1);
        }

        @media (max-width: $screen-md-max) {
            float: none !important;
            margin-right: 0;
            margin-left: 0;
            padding: 0;
            border-left: none;
        }

        @media (max-width: $screen-xs-max) {
            .dropdown-menu {
                position: relative;
                display: block;
                left: auto;
                float: none;
                padding: 0;
                min-width: 0;
                border: none;
                box-shadow: none;

                & > li > a {
                    padding: 0 1.5rem;
                    color: $primary;
                }
            }
        }
    }

    .dropdown-menu {
        border-radius: 0;

        .active>a:after{
            @media (min-width: $screen-sm-min) {
                width: u(.5rem);
                height: 100%;
                bottom: 0;
            }
        }
    }

    .navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:hover {
        background-color: transparent;
    }
}

.right-nav {
    // padding-left: u(1rem);

    @media (max-width: $screen-xs-max) {
        padding-left: 0;
    }

    @media (min-width: $screen-lg-min) {
        padding-left: 0;
    }
}

.mobile-item a {
    @media (max-width: $screen-xs-max) {
        color: $primary !important;
        @include opacity(1);
    }
}

.page-inner .mobile-item a {
    @media (max-width: $screen-xs-max) {
        margin-bottom: 9px;
    }
}

.right-nav {
    padding: u(1.5rem 1.5rem 1rem 3rem);
    border-left: u(.1rem) solid rgba(#fff, .4);

    @media (max-width: $screen-md-max) {
        margin: 0;
        padding: u(1rem 0rem 1rem .5rem);
        border: none;
    }

    @media (max-width: $screen-xs-max) {
        padding-left: 0;
        padding-right: 0;
    }
}

#page-homepage {
    .nav {
        display: inline-block;
        float: none;
        padding: u(1.5rem 1.5rem 1rem 3rem);

        @media (max-width: $screen-md-max) {
            padding: u(1.5rem 1.5rem 1rem 1rem);
        }

        @media (max-width: $screen-xs-max) {
            padding: u(0rem 1.5rem 1rem 0);
        }
    }

    .right-nav {
        margin-left: u(3rem);
        border-left: u(.1rem) solid $border;

        @media (max-width: $screen-md-max) {
            margin-left: 0;
            border: none;
        }

        >li>a {
            color: $primary;
            @include opacity(1);
        }
    }
}

#quick-links {
    padding: u(1.4rem 3rem 0 3rem);

    @media (max-width: $screen-md-max) {
        display: inline-block;
        padding: u(1.4rem 1.5rem 0 3rem);
    }

    @media (max-width: $screen-xs-max) {
        display: block;
        padding: 0;

        li {
            display: inline-block;
        }
    }
}

.page-inner {
    .right-nav {
        @media (max-width: $screen-md-max) and (min-width: $screen-sm-min) {
            padding: u(1.4rem 0 1.4rem .5rem);
        }
    }

    @media (min-width: $screen-sm-min) {
        #site-header {
            position: fixed;
            border-radius: 0;
            background-color: $primary;

            .navbar-default {
                margin-top: 0;
                padding-left: u(24rem);
                background: none;
                box-shadow: none;
                border-radius: 0;

                @media (max-width: $screen-md-max) {
                    padding: u(0 0 1.5rem 24rem);
                }
            }

            .navbar-right {
                border-left: u(0.1rem solid rgba(#fff, .4));

                @media (max-width: $screen-md-max) {
                    border: none;
                }
            }

            .navbar-collapse li a {
                color: #FFF;
            }

            .navbar-header {
                display: none;
            }

            .dropdown-menu > li > a {
                color: $dark;
            }
        }
    }

    @media (max-width: $screen-xs-max) {
        #site-header .right-nav a {
            color: $primary;
            @include opacity(1);
        }
    }
}

#inner-top-navigation {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
}
