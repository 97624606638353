.btn {
    padding: u(1.2rem 4.5rem);
    transition: background-color .3s ease;

    &:hover, &:active, &:focus {
        box-shadow: none;
    }

    &.btn-dark {
        background-color: $primary-dark;
        font-size: u(1.6rem);
        color: #FFF;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: u(.2rem);

        &:hover {
            background-color: darken($primary-dark, 3%);
        }
    }

    &.btn-text-only {
        position: relative;
        display: inline-block;
        margin-top: u(1.5rem);
        padding: 0 0 u(1.6rem) 0;
        font-size: u(1.6rem);
        color: $primary;
        font-weight: 600;

        &:after {
            position: absolute;
            display: block;
            width: 100%;
            height: u(.2rem);
            background-color: $primary;
            margin-top: u(1.6rem);
            content: '';
        }
    }
}
