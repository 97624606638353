.card-container {
    margin-top: u(10rem);
}

.card {
    background-color: #fff;
    border-radius: 2px;
}

.card {
    background-color: #FFF;
    box-shadow: 0 1px 1px 0 rgba(0,0,0,0.10), 0 2px 2px 0 rgba(0,0,0,0.11);

    &.faq {
        position: relative;
        margin-bottom: u(7rem);

        @media (max-width: $screen-sm-max) {
            height: auto;

            .card-content {
                text-align: center;

                ul {
                    display: inline-block;
                }

                .links {
                    text-align: left;
                }
            }
        }

        li {
            list-style: none;
        }

        .faq-icon {
            position: relative;
            display: block;
            margin: u(-3.5rem) auto 0 auto;
            width: u(7rem);
            height: u(7rem);
            background-color: $dark;
            font-size: u(3.5rem);
            color: #FFF;
            line-height: u(6.8rem);
            border-radius: 100%;

            img {
                display: inline-block;
                width: u(4rem);
                margin: auto;
            }
        }

        .title {
            display: block;
            margin-bottom: u(3rem);
            padding-bottom: u(2rem);
            font-size: u(2rem);
            text-align: center;
            border-bottom: 1px solid rgba($border, .5);

            span {
                position: relative;
                display: inline-block;

                &:after {
                    position: relative;
                    display: block;
                    width: 100%;
                    height: 2px;
                    max-width: u(10rem);
                    top: u(2rem);
                    margin: auto;
                    background-color: $primary;
                    content: '';
                }
            }
        }

        &.faq-primary {
            background-color: $primary;

            a {
                color: #FFF;

                &:after {
                    background-color: #FFF;
                }
            }

            .vertical-align {
                position: relative;
            }

            .icon {
                display: block;
                font-size: u(5rem);
                margin-bottom: u(1rem);
            }

            .card-content {
                text-align: center;
            }

            @media (max-width: $screen-xs-max) {
                margin-top: u(-3.5rem);
            }
        }
    }

    &.card-customer {
        width: 100%;
        margin-bottom: u(3rem);
        padding-top: u(3rem);
        opacity: 0;
        transform: translateY(50%);
        box-shadow: 0 5px 8px 0 rgba($primary-dark, .1), 0 2px 30px 0 rgba($primary-dark, .1);
        transition: opacity .5s ease .5s, transform .6s ease .5s;

        h4 {
            font-size: u(2.4rem);
            color: $primary;
            font-weight: 700;

            @media (max-width: $screen-sm-max) and (min-width: $screen-sm-min) {
                font-size: u(2rem);
            }
        }

        p {
            font-size: u(1.6rem);
            line-height: 1.6;


            @media (max-width: $screen-sm-max) and (min-width: $screen-sm-min) {
                font-size: u(1.4rem);
            }
        }

        time {
            display: block;
        }

        .customer-details {
            position: absolute;
            width: 100%;
            left: 0;
            bottom: 0;
            margin-top: u(3rem);
            padding: u(1.5rem 3rem);
            border-top: 1px solid $border;
            overflow: hidden;

            @media (max-width: $screen-sm-max) {
                position: relative;
                left: auto;
                bottom: auto;
                padding: u(1.5rem 0 0 0);
            }
        }

        .customer-avatar {
            position: relative;
            width: u(4rem);
            height: u(4rem);
            margin-right: u(1.5rem);
            background-color: $screen-bg;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            border-radius: 20%;
            overflow: hidden;
        }

        .name {
            color: $primary;
            font-weight: 700;
        }
    }

    .card-content {
        padding: u(0 3rem 9rem 3rem);
        text-align: left;

        @media (max-width: $screen-sm-max) {
            padding: u(0 3rem 3rem 3rem);
        }

        li {
            margin-bottom: u(1rem);

            &:hover {
                a {
                    color: $primary;
                }
            }
        }

        a {
            color: $dark;
            text-decoration: none;
            transition: color .3s ease;
        }
    }
}

.currently-viewing-5, .currently-viewing-6, .currently-viewing-7, .section.active {
    .card.card-customer {
        opacity: 1;
        transform: translateY(0);
    }

    .card-container {
        display: flex;
        flex-wrap: wrap;

        > div {
            position: relative;

            &:nth-child(2) {
                .card.card-customer {
                    transition-delay: .55s;
                }
            }

            &:nth-child(3) {
                .card.card-customer {
                    transition-delay: .65s;
                }
            }

            &:nth-child(4) {
                .card.card-customer {
                    transition-delay: .6s;
                }
            }

            &:nth-child(5) {
                .card.card-customer {
                    transition-delay: .7s;
                }
            }

            &:nth-child(6) {
                .card.card-customer {
                    transition-delay: .75s;
                }
            }
        }
    }
}

.page-frequently-asked-questions {
    .page-header {
        @media (min-width: $screen-sm-min) {
            padding: u(6rem 0 6rem);
        }
    }
    .card-container {
        display: flex;
        flex-wrap: wrap;
        max-width: u(117rem);
        margin-left: auto;
        margin-right: auto;

        @media (max-width: $screen-sm-max) {
            display: block;
            flex-wrap: none;
        }

        >div {
            display: inline-flex;
            flex: 1 0 33%;

            @media (max-width: $screen-md-max) {
                flex: 1 0 49%;
            }

            @media (max-width: $screen-sm-max) {
                display: block;
            }
        }
    }

    .card.faq.faq-primary {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;

        .card-content {
            position: absolute;
            width: 100%;
            height: 100%;
            padding: 0;

            @media (max-width: $screen-sm-max) {
                position: relative;
                height: auto;
            }
        }
    }
}
