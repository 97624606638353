@font-face {
    font-family: 'WazzapMigrator';
    src:  url('../fonts/WazzapMigrator.eot?dm69wv');
    src:  url('../fonts/WazzapMigrator.eot?dm69wv#iefix') format('embedded-opentype'),
    url('../fonts/WazzapMigrator.ttf?dm69wv') format('truetype'),
    url('../fonts/WazzapMigrator.woff?dm69wv') format('woff'),
    url('../fonts/WazzapMigrator.svg?dm69wv#WazzapMigrator') format('svg');
    font-weight: normal;
    font-style: normal;
}

.wm-icons {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'WazzapMigrator' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
}

.logo:before {
    content: "\e900";
}
