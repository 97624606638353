.page-changelog, .node-type-changelog {
    .page-header time {
        @media (max-width: $screen-md-max) {
            display: block !important;
        }
    }

    .views-row {
        margin-bottom: u(3rem);
        padding-bottom: u(3rem);
        border-bottom: 1px solid rgba($border, .3);
    }

    .field-content {
        line-height: 1.8;

        a {
            display: block;
            margin: u(.5rem 0 1rem 0);
            font-size: u(2rem);
            color: $primary;
            font-weight: 700;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}
