@keyframes bounce {
  0% {
    transform: translateY(0);
  }

  35% {
    transform: translateY(-25%);
  }

  50% {
    transform: translateY(30%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes navbarToggleFeedback {
    0% {
        opacity: 0;
        transform: scale(0);
    }

    50% {
        opacity: 1;
        transform: scale(1.4);
    }

    100% {
        opacity: 0;
        transform: scale(1);
    }
}

@keyframes pageReveal {
  from {
    transform: translateY(35%);
  }

  to {
    transform: translateY(-9rem);
  }
}
