.page-press  {
    tr.even, tr.odd {
        background-color: transparent;
        border-top: 1px solid rgba($border, .3);

        td {
            padding: 15px 0;
        }
    }

    .views-table th {
        padding-bottom: u(2rem);
    }

    .view-content tbody .views-field-title {
        font-size: u(1.8rem);
        color: $primary;
        font-weight: 700;
    }

    .views-field-field-country {
        text-align: right;
    }

    .views-table {
        width: 100%;
    }
}
