::selection {
    background: #f5f5f5;
}

::-moz-selection {
    background: #f5f5f5;
}

img::selection {
    background: transparent;
}

img::-moz-selection {
    background: transparent;
}

select:-moz-focusring, option:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;

    &:active, &:focus {
        color: transparent;
        text-shadow: 0 0 0 #000;
    }
}

html, body {
    position: relative;
    height: 100%;
    min-height: 100%;
    font-family: sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html {
    &[dir='rtl'] {
        .page-inner {
            main {
                padding: u(9rem 26rem 0 0);

                @media (max-width: $screen-sm-max) {
                    padding: u(9rem 20rem 0 0);
                }
            }

            #site-header .navbar-default {
                padding-right: u(26rem);
                padding-left: 0;
            }
        }
    }

    @media (max-width: $screen-xs-max) {
        font-size: 9px;
    }
}

body {
    font-family: $sans-serif;
    font-size: u(1.4rem);
    color: $dark;
}

h2 {
    font-family: $sans-serif;
    font-weight: 700;
    color: $dark;
}

ul {
    padding: 0;
}

.section-white {
    background-color: #FFF;
}

.paragraph-default {
    font-size: u(1.4rem);
    color: $dark;
    @include opacity(.7);
}

.title {
    margin-bottom: 0;
}

.subtitle {
    margin: u(1.5rem 0);
    font-family: $sans-serif;
    font-size: u(1.8rem);
    color: $dark;
    line-height: 1.6;
    @include opacity(.7);
}

.hero {
    h1 {
        font-size: u(5rem);
        line-height: 1.2;

        @media (max-width: $screen-xs-max) {
            font-size: u(3.7rem);
        }
    }

    .subtitle {
        font-size: u(2.4rem);
        line-height: 2;
        color: #fff;
        @include opacity(.8);

        @media (max-width: $screen-xs-max) {
            margin-bottom: u(3rem);
            font-size: u(2rem);
        }
    }
}

.vertical-align {
    position: absolute;
    display: table;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    @media (max-width: $screen-xs-max) {
        position: relative;
    }

    .align-top, .align-middle, .align-bottom {
        display: table-cell;
    }

    .align-top {
        vertical-align: top;
    }

    .align-middle {
        vertical-align: middle;
    }

    .align-bottom {
        vertical-align: bottom;
    }
}

.primary-gradient {
    background: #1ebea5;
    background: -moz-linear-gradient(-45deg, #1ebea5 42%, #3da6af 100%);
    background: -webkit-linear-gradient(-45deg, #1ebea5 42%,#3da6af 100%);
    background: linear-gradient(135deg, #1ebea5 42%,#3da6af 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1ebea5', endColorstr='#3da6af',GradientType=1 );
}

.introduction {
    display: block;
    margin-bottom: u(1.5rem);
    font-size: u(2rem);
    color: $dark;
    font-weight: 500;
    @include opacity(.4);

    @media (max-width: $screen-xs-max) {
        text-align: center;
    }
}

.fp-slides, .fp-slide, .fp-slidesContainer {
    height: auto;
}

.features-list {
    li {
        font-size: u(3.2rem);

        @media (max-width: $screen-sm-max) {
            font-size: u(2.4rem);
        }

        @media (max-width: $screen-xs-max) {
            font-size: u(2.8rem);
        }

        strong {
            text-transform: capitalize;
        }

        .icon {
            position: relative;
            top: .25em;
            margin-right: u(1.5rem);
            font-size: u(4.2rem);
            color: $features-1;
        }

        &:nth-child(3n+1) {
            .icon {
                color: $features-1;
            }
        }

        &:nth-child(3n+2) {
            .icon {
                color: $features-2;
            }
        }

        &:nth-child(3n+3) {
            margin-bottom: u(4.5rem);

            .icon {
                color: $features-3;
            }
        }
    }

    .features-slide {
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        max-width: u(24rem);
        height: u(17rem);
    }

    .carousel-indicators {
        width: auto;
        bottom: 0;
        left: 0;
        margin: u(3rem 0 0 .5rem);

        li {
            display: inline-block;
            width: u(1rem);
            height: u(1rem);
            margin-right: u(.5rem);
            margin-bottom: 1px;
            background-color: $dark;
            border-radius: 100%;
            @include opacity(.4);
            cursor: pointer;
            transition: background-color .3s ease, opacity .3s ease;

            &:hover {
                background-color: $primary-light;
                @include opacity(1);
            }

            &.active {
                background-color: $primary;
                @include opacity(1);
            }
        }

        @media (max-width: $screen-xs-max) {
            display: block;
            width: 100%;
            text-align: center;
        }
    }

    @media (max-width: $screen-xs-max) {
        text-align: center;
        margin-bottom: u(1.5rem);

        .carousel-inner {
            display: inline-block;
            width: auto;
            text-align: left;
        }
    }
}

/*.baloons-container {
padding-left: 0;

.balloon {
position: relative;
display: inline-block;
margin-bottom: u(3rem);
padding: u(1.8rem 3rem);
list-style: none;
font-size: u(1.6rem);
font-weight: 500;
border-radius: 4px;

&:before {
position: absolute;
width: 0;
height: 0;
top: 50%;
margin-top: u(-1rem);
left: u(-1rem);
border-top: u(1rem) solid transparent;
border-bottom: u(1rem)solid transparent;
border-right: u(1rem) solid $dark;
content: '';
}

&.balloon-vert {
background-color: $primary-light;

&:before {
border-right-color: $primary-light;
}
}

&.balloon-purple {
background-color: $secondary-light;

&:before {
border-right-color: $secondary-light;
}
}
}
}*/

.page-inner {
    background-color: $page-bg;

    main {
        margin: 0;
        padding: u(9rem 0 0 26rem);

        @media (max-width: $screen-sm-max) {
            padding: u(9rem 0 0 20rem);
        }

        @media (max-width: $screen-xs-max) {
            padding: u(9rem 0 0 0);
        }

        @media (max-width: $screen-xs-max) {
            padding: u(15rem 0 0);
        }
    }

    .main-content {
        img {
            max-width: 100%;
            height: auto;
        }

        .language-icon {
            width: u(3rem);
            height: u(3rem);
            border-radius: 100%;
            margin-bottom: u(3rem);
        }

        .content {
            margin-bottom: u(3rem);
        }

        li[class^=“translation_“] {
            display: inline-block;
            padding: 0;
            width: auto;
            margin-top: 0;
            margin-left: .5rem;
            margin-bottom: u(2rem);
        }
    }
}

.item-list .pager {
    li {
        margin: 0;

        &> a {
            text-decoration: none;
        }
    }
}

.carousel-fade {
    .carousel-inner {
        .item {
            transition-property: opacity;
        }

        .item,
        .active.left,
        .active.right {
            opacity: 0;
        }

        .active,
        .next.left,
        .prev.right {
            opacity: 1;
        }

        .next,
        .prev,
        .active.left,
        .active.right {
            left: 0;
            transform: translate3d(0, 0, 0);
        }
    }

    .carousel-control {
        z-index: 2;
    }
}

// TEMP
.page {
    max-width: u(117rem);
    margin: auto;
    padding: u(6rem 9rem);
    background: #fff;
    box-shadow: 0 1px 1px 0 rgba(0,0,0,0.10), 0 2px 2px 0 rgba(0,0,0,0.11);
    transform: translateY(u(-9rem));
    animation-name: pageReveal;
    animation-duration: .8s;
    text-align: left;

    @media (max-width: $screen-sm-max) {
        padding: u(3rem);
    }

    p {
        font-size: u(1.8rem);
    }

    a {
        color: $primary;
        font-weight: 700;
        text-decoration: underline;
    }

    ol,ul {
        font-size: u(1.65rem);
    }

    a, blockquote {
        word-break: break-word;
    }

    blockquote {
        margin: u(0.5rem 0);
        font-weight: 700;
        border-color: $primary;
    }

    iframe {
        @media (max-width: $screen-xs-max) {
            width: 100% !important;
        }
    }

    .alert-success {
        margin: u(4.5rem 0);
        padding: u(3rem);
        background-color: rgba($primary, .1);
        font-size: u(1.8rem);
        color: $dark;
        font-style: italic;
        border: none;

        a {
            color: $primary;
            font-style: normal;
            font-weight: 700;
        }
    }

}

.page-header {
    position: relative;
    margin: 0;
    padding: u(6rem 0 12rem);
    background-color: darken($primary, 5%);
    color: #fff;
    overflow: hidden;

    &:before {
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-image: url('../images/pattern.png');
        background-blend-mode: multiply;
        @include opacity(.05);
        z-index: 0;
        content: '';

        @media #{$retina} {
            background-image: url('../images/@2x/pattern.png');
        }
    }

    h1 {
        position: relative;
        max-width: u(60rem);
        margin: auto;
        line-height: 1.4;

        @media (max-width: $screen-xs-max) {
            max-width: none;
            width: 100%;
            padding: u(0 3rem);
            font-size: u(3.2rem);
            line-height: inherit;
        }
    }

    time {
        position: relative;
        display: block;
        margin: u(1.5rem auto 0 auto);
        font-size: u(1.8rem);
        font-weight: 500;
        @include opacity(.8);
    }
}

div.field-name-field-image-slideshow {
	.field-item {
	    margin-top: 10px;
	    margin-bottom: 10px;
		text-align: center;
	}
}

.article-container {
    @media (max-width: $screen-sm-max) and (min-width: $screen-sm-min) {
        width: 94%;
        margin-left: 3%;
    }

}

.item-list .pager li.pager-current {
    @media (max-width: $screen-sm-max) {
        display: block;
    }
}