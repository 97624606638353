.page-contact {
    legend {
        display: none;
    }
}

#facebook-wrapper {
    margin-bottom: u(4.5rem);

    @media (max-width: $screen-xs-max) {
        display: none;
    }
}
