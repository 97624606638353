.page-releases {
    tr.even, tr.odd {
        background-color: transparent;
        border-top: 1px solid rgba($border, .3);
    }

    .view-content th {
        border-bottom: none;
    }

    .views-table {
        width: 100%;
        margin: auto;

        th {
            padding-bottom: u(2rem);
        }
    }

    tbody .views-field-field-release {
        color: $primary;
        font-weight: 700;

        @media (min-width: $screen-sm-min) {
            font-size: u(2rem);
        }
    }

    .views-field.views-field-nothing {
        text-align: right;
    }

    .view-content img {
        width: u(18rem);
        float: right;
        transition: opacity .3s ease;

        @media (max-width: $screen-xs-max) {
            width: 100%;
            max-width: u(14rem);
        }

        &:hover {
            opacity: .7;
        }
    }
}
