.contact-form {
    input[type="text"], textarea {
        width: 100%;
        margin-bottom: u(1.5rem);
        padding: u(1rem);
        background-color: $page-bg;
        border: 1px solid rgba($border, .3);
        border-radius: 3px;
        outline: none;

        &.error {
            border: 2px solid red;
        }
    }

    input[type="submit"] {
        margin-top: u(3rem);
        padding: 0;
        background-color: transparent;
        font-size: u(1.6rem);
        color: $primary;
        font-weight: 600;
        text-transform: uppercase;
        border: none;
    }
}
