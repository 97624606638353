.dropdown {
    font-size: u(1.3rem);

    .title {
        position: relative;
        font-size: u(1.6rem);

        .icon {
            position: absolute;
            top: 50%;
            right: u(1.5rem);
            margin-top: u(-1.2rem);
            transform: rotate(0deg);
            transition: transform .3s ease;
        }
    }

    &.dropdown-open {
        .title .icon {
            transform: rotate(180deg);
        }

        .dropdown-content {
            height: auto;
        }
    }
}

.dropdown-content {
    height: 0;
    overflow: hidden;
    transition: height .4s ease;
}
