.inner-left-navigation {
    position: fixed;
    display: block;
    width: u(26rem);
    height: 100%;
    z-index: 10;
    border-right: u(.1rem) solid rgba(0,0,0,.14);
    overflow: hidden;

    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        width: u(20rem);

        .navbar-brand {
            font-size: u(1.4rem);

            .icon {
                margin-right: u(.5rem);
                font-size: u(2.2rem);
            }
        }
    }

    nav {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        padding-top: u(3rem);
        background-color: #FFF;
        overflow-y: scroll;
    }

    .navbar-brand {
        width: 100%;
        padding: u(2.1rem 3rem 2.3rem 3rem);
        background-color: #fff;
        border-bottom: 1px solid #e0e0e0;
    }

    .dropdown {
        margin-bottom: u(3rem);

        .title {
            display: block;
            width: 100%;
            padding: u(0 4rem 0 3rem);
            color: $dark;

            @media (max-width: $screen-xs-max) {
                padding: u(0 3rem) !important;
                font-size: u(2rem);
            }
        }
    }

    .dropdown-content {
        display: block;
        margin-top: u(1.5rem);

        li {
            display: block;
            width: 100%;
            margin-bottom: u(1.5rem);
            padding: u(0 3rem);

            &.active {
                a {
                    color: $primary;
                    font-weight: 700;
                }
            }
        }

        a {
            color: $dark;

            @media (max-width: $screen-xs-max) {
                font-size: u(1.6rem);
            }
        }
    }
}

.sidebar-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: auto;
    height: 100%;
    background-color: transparent;
    z-index: 100;
    transition: all .4s;

    @media (max-width: $screen-xs-max) {
        width: 100%;
        right: 0;
        left: auto;
        opacity: 0;
        z-index: -1;

        &.open {
            opacity: 1;
            z-index: 100;
            background-color: rgba(0, 0, 0, .8);
            transition-delay: .15s;

            .inner-left-navigation {
                width: calc(100% - 50px);
                transform: translateX(0);
                transition-delay: .15s;
            }
        }
    }

    .inner-left-navigation {
        position: absolute;
        background-color: white;
        left: 0;
        top: 0;
        height: 100%;

        @media (max-width: $screen-xs-max) {
            transform: translateX(100%);
            left: auto;
            right: 0;
            box-shadow: -10px 0 10px rgba($dark, .4);
            transition: transform .4s ease-out;
        }
    }
}

#page-homepage {
    .sidebar-wrapper {
        @media (min-width: $screen-sm-min) {
            visibility: hidden;
            z-index: -1;
        }
    }
}
