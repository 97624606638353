.page-frequently-asked-questions {
    @media (max-width: $screen-sm-max) {
        .page-header {
            padding: u(6rem 0 8rem);
        }

        .card-content {
            text-align: center;
            ul {
                display: inline-block;
                text-align: left;
            }
        }

        .card-container {
            margin-top: 0;

            li {
                margin-bottom: u(1.5rem);
            }
        }
    }
}
